import { Link } from "gatsby"
import React from "react"
import Header from "../components/Header"
import SEO from "../components/SEO"

const Page = () => {
  return (
    <>
      <SEO title="Tietosuojaseloste" />
      <Header />
      <main>
        <h1>Tietosuojaseloste</h1>
        <p>Päivitetty 17.11.2021</p>

        <div className="text-left" style={{ marginTop: 50 }}>
          <h2>1. REKISTERINPITÄJÄ JA YHTEYSHENKILÖ</h2>

          <p>Lumme Energia Oy (jatkossa Lumme Energia)</p>

          <p>Y-tunnus: 2839885-8</p>

          <p>Käyntiosoite: Prikaatinkatu 3A, 50101 Mikkeli</p>

          <p>Puhelin: 0800 90110</p>

          <p>Verkkopalvelu: www.lumme-energia.fi Yhteyshenkilö rekisteriasioissa: Mika Ahola; puh. 010 207 4844</p>

          <h2>2. YHTEYDENOTOT</h2>

          <p>
            Rekisteröity voi oikeuksiensa toteuttamiseksi olla aina yhteydessä Lumme Energian asiakaspalveluun
            seuraavien yhteyksien kautta:
          </p>

          <p>Puhelin: 0800 90110</p>

          <p>Sähköposti: asiakaspalvelu@lumme-energia.fi</p>

          <p>Käyntiosoite: Prikaatinkatu 3A, 50101 Mikkeli</p>

          <p>Rekisteröidyn kannattaa varautua luotettavalla tavalla todistamaan henkilöllisyytensä.</p>

          <p>
            Lumme Energia toteuttaa asiakkaan pyynnön viimeistään kuukauden kuluessa pyynnön saamisesta ja pyytäjän
            tunnistamisesta, ellei ole erityistä syytä pidentää vastausaikaa.
          </p>

          <h2>3. REKISTERIN NIMI</h2>

          <p>
            Lumme Energian joulukalenterin osallistujarekisteri. Rekisterin rekisteröityjä ovat Lumme Energian
            joulukalenteria
          </p>

          <p>
            käyttäneet asiakkaat, jotka ovat halunneet osallistua arvontaan jättämällä nimensä ja sähköpostiosoitteensa.
          </p>

          <h2>4. HENKILÖTIETOJEN KÄSITTELYN TARKOITUS JA REKISTERIN PITÄMISEN PERUSTA</h2>

          <p>
            Lumme Energia rekisterinpitäjänä tai sen sopimuksin valtuutetut henkilötietojen käsittelijät käyttävät
            asiakkaiden ja potentiaalisten asiakkaiden henkilötietoja tietosuojalain mukaisesti vain mahdollisesta
            arvontavoitosta ilmoittamiseen ja palkintojen toimittamiseen.
          </p>

          <p>Henkilötietojen käsittely perustuu arvontaan osallistuvan käyttäjän suostumukseen.</p>

          <h2>5. REKISTERIN TIETOSISÄLTÖ</h2>

          <p>Tallennettavat tiedot:</p>
          <ul>
            <li>nimi</li>
            <li>sähköposti</li>
          </ul>
          <h2>6. SÄÄNNÖNMUKAISET TIETOLÄHTEET</h2>

          <p>Asiakkaita koskevat tiedot saadaan asiakkailta arvontaan osallistumisen yhteydessä.</p>

          <p>
            Tietoja kerätään myös evästeiden ja vastaavien tekniikoiden avulla kulloinkin voimassa olevan sääntelyn
            mahdollistamin menettelytavoin. Evästeiden käytöstä kerrotaan kunkin digitaalisen palvelun käyttöehtojen
            evästelauseessa.
          </p>

          <p>Rekisterin tietoja ei yhdistetä muiden rekistereiden tietoihin.</p>

          <h2>7. SÄÄNNÖNMUKAISET TIETOJEN LUOVUTUKSET</h2>

          <p>
            Rekisteröidyn tietoja ei luovuteta Lumme Energian lukuun toimivien vastaanottajien tai palvelukokonaisuuden
            tuottamiseen osallistuvien kolmansien tahojen ulkopuolelle ilman rekisteröidyn nimenomaista suostumusta.
          </p>

          <p>
            Viranomaisille tietoja luovutetaan lain edellyttämissä tapauksissa, kuten esim. väärinkäytösten
            selvittämisessä ja ehkäisyssä.
          </p>

          <h2>8. TIETOJEN SÄILYTYS</h2>

          <p>
            Kaikkien arvontoihin osallistuneiden tietoja säilytetään yhden kuukauden ajan pääpalkinnon arvonnasta,
            paitsi sellaisessa tapauksessa että arvonnan voittajaa ei saada kontaktoitua kyseisen ajan sisällä.
          </p>

          <h2>9. TIETOJEN SIIRTO EU:N TAI EUROOPAN TALOUSALUEEN ULKOPUOLELLE</h2>

          <p>Henkilötietoja ei siirretä Euroopan unionin tai Euroopan talousalueen ulkopuolelle.</p>

          <h2>10. REKISTERIN SUOJAUKSEN PERIAATTEET</h2>

          <p>
            Rekisteritiedot on suojattu henkilökohtaisilla käyttäjätunnuksilla ja salasanoilla. Vain tietoihin
            oikeutetut ja määritellyt käyttäjät pääsevät käsittelemään ylläpitämiään tietoja. Rekisteristä otetaan
            säännöllisin väliajoin varmuuskopiot. Asiakasrekisterin tiedot ovat tietokannoissa, jotka ovat suojattu
            palomuurilla, salasanoilla ja muilla teknisillä keinoilla.
          </p>

          <h2>11. REKISTERÖIDYN OIKEUDET</h2>

          <h3>11.1 Oikeus saada pääsy tietoihin ja tarkastaa tiedot</h3>

          <p>
            Rekisteröidyllä on oikeus saada jäljennös paperisessa tai yleisesti käytetyssä sähköisessä muodossa ja
            tarkastaa tässä selosteessa kuvatut, omat tietonsa. Tiedot saa kohdassa 2. kerrottujen yhteystietojen
            mukaisesti.
          </p>

          <h3>11.2 Oikeus tietojen oikaisemiseen ja käsittelyn rajoittamiseen</h3>

          <p>
            Rekisteröidyllä on oikeus tietojen oikaisuun toimittamalla puutteelliset tai virheelliset tiedot
            selvityksineen kohdassa 2. kerrottujen yhteystietojen mukaisesti.
          </p>

          <p>
            Rekisteröidyllä on myös oikeus käsittelyn rajoittamiseen sitä pyytäessään, kunnes tiedot on saatettu ajan
            tasalle.
          </p>

          <h3>11.3 Oikeus tietojen poistamiseen</h3>

          <p>Rekisteröidyllä on oikeus pyytää tietojensa poistamista.</p>

          <p>Poistopyyntö voidaan esittää kohdassa 2. kerrottujen yhteystietojen mukaisesti.</p>

          <h3>11.4 Oikeus vastustaa automaattista päätöksentekoa mukaan lukien profilointi</h3>

          <p>
            Digitaalisissa palvelukanavissa on mahdollista, että rekisteröidyn antamien tietojen perusteella
            tarkistetaan automaattisesti esimerkiksi sopimuskelpoisuutta.
          </p>

          <p>
            Automaattista käsittelyä ja sen mukaista päättelyä käytettäessä rekisteröidyllä on sitä oikeus vastustaa,
            mikäli menettely ei ole välttämätön sopimuksen tekemistä tai täytäntöönpanoa varten.
          </p>

          <p>
            Tällaisissa tapauksissa kerrotaan rekisteröidylle vaihtoehtoiset tavat edetä asiansa hoitamisessa muilla
            kanavilla.
          </p>

          <h3>11.5 Oikeus vastustaa evästeiden käyttöä</h3>

          <p>
            Lumme Energian digitaalisten palvelujen yhteydessä käytämme evästeitä. Palvelujen käyttöehdoissa on kerrottu
            evästeiden käytön estämisestä ja sen vaikutuksesta palveluun. Joulukalenteria voi käyttää ja arvontoihin voi
            osallistua suostumatta evästeiden käyttöön.
          </p>

          <h3>11.6 Oikeus tehdä valitus valvontaviranomaiselle</h3>

          <p>
            Mikäli rekisteröity katsoo, että hänen tietojaan on käsitelty tietosuojaselosteen ja kulloinkin voimassa
            olevan lainsäädännön vastaisesti, hänellä on oikeus valittaa toimivaltaiselle valvontaviranomaiselle.
          </p>

          <h4>Tietosuojaviranomaisen yhteystiedot:</h4>

          <p>Käyntiosoite: Ratapihantie 9 (6 krs.), 00520 Helsinki</p>

          <p>Postiosoite: PL800, 00521 Helsinki</p>

          <p>Vaihde: 029 566 6700</p>

          <p>Sähköposti: tietosuoja@om.fi</p>
        </div>

        <nav className="bottom-nav">
          <Link to="/">
            <button>Etusivulle</button>
          </Link>
        </nav>
      </main>
    </>
  )
}

export default Page
